import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import Success from "../Common/FormSuccess"
import Loader from "../Index/Loader"

const BookingForm = props => {
  const [SubmitSuccess, setSubmitSuccess] = useState(false)
  const [week, setWeek] = useState([])
  const [timeslot, setTimeSlot] = useState([])
  const [selectedDate, setSelectedDate] = useState("")
  const [request, setRequest] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (SubmitSuccess) {
      const timer = setTimeout(() => {
        setSubmitSuccess(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [SubmitSuccess])

  useEffect(() => {
    // populate week for radio buttons
    var weekArray = []
    var date = new Date()
    // var utc = date.getTime() + date.getTimezoneOffset() * 60000
    // var timezoneOffset = date.getTimezoneOffset() / 60
    var dateIncrementer = 0
    var dateslots = 7
    // var date1 = new Date(utc - 3600000 * timezoneOffset)
    if (date.getHours() >= 17) {
      dateIncrementer = 1
      dateslots += 1
      var nextday = new Date()
      generateSlots(nextday.setDate(nextday.getDate() + 1))
      setSelectedDate(nextday)
    } else {
      generateSlots(new Date())
      setSelectedDate(new Date())
    }
    let firstIteration = true
    for (dateIncrementer; dateIncrementer <= dateslots; dateIncrementer++) {
      var date1 = new Date()
      date1.setDate(date1.getDate() + dateIncrementer)

      var dayname = date1.toString().split(" ")[0]
      var daynumber = date1.getDate()
      weekArray.push({
        id: dateIncrementer,
        day: dayname,
        date: daynumber,
        isodate: date1,
        checked: firstIteration ? true : false,
      })
      firstIteration = false
    }
    // remove saturday and sunday
    var week = weekArray.filter(function (el) {
      return el.day != "Sat" && el.day != "Sun"
    })
    setWeek(week)
  }, [])

  function generateSlots(dateStr) {
    var date = new Date(dateStr) //check iso
    var timeSlotArr = []
    var from = 8
    var to = 9
    if (new Date().toDateString() == date.toDateString()) {
      var hour = date.getHours()
      from = hour + 1
      to = hour + 2
      if (hour < 8) {
        from = 8
        to = 9
      }
    }
    while (from <= 17 && to <= 18) {
      var slotValue
      if (from == 12) {
        slotValue = "12PM - "
      } else {
        slotValue = from > 12 ? (from % 12) + "PM - " : from + "AM - "
      }
      if (to == 12) {
        slotValue = slotValue + "12PM"
      } else {
        slotValue =
          to > 12 ? slotValue + (to % 12) + "PM" : slotValue + to + "AM"
      }
      timeSlotArr.push(slotValue)
      from++
      to++
    }
    setTimeSlot(timeSlotArr)
  }

  const ValidateEmail = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!RegEx.test(value)) {
      error = "Invalid Email"
    }
    return error
  }

  const ValidateName = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[a-zA-Z ]{2,30}$/
    if (!RegEx.test(value)) {
      error = "Invalid Name"
    }
    return error
  }

  const ValidatePostCode = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[0-9]{4}$/
    if (!RegEx.test(value)) {
      error = "Invalid Postcode"
    }
    return error
  }

  const ValidatePhone = value => {
    let error
    if (value.length == 12) {
      if (!value.startsWith("+")) {
        return (error = "Invalid Phone Number")
      } else {
        value = value.slice(1)
        return (error = !/^\d{11}$/.test(value) ? "Invalid Phone Number" : "")
      }
    } else if (value.length == 10) {
      const RegEx = /^\d{10}$/
      if (!RegEx.test(value)) {
        error = "Invalid Phone Number"
      }
    } else {
      return (error = "Invalid Phone Number")
    }
    return error
  }

  const ValidateDateSlot = value => {
    let error
    if (!value) {
      return (error = "required")
    }
    return error
  }
  const ValidateTimeSlot = value => {
    let error
    if (!value) {
      return (error = "required")
    }
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    postalCode: "",
    requestedDate: "",
    requestedTimeSlot: "",
    subscribeToNewsletter: false,
  }

  const onSubmit = async (values, { resetForm }) => {
    var offset = new Date().getTimezoneOffset() * 60000
    var localISOTime = new Date(selectedDate - offset).toISOString()
    values.requestedDate = localISOTime
    if (props.Broker) {
      values.agentId = props.Broker.id
      // if (`${process.env.GATSBY_ENVIRONMENT}` == "staging") {
      //   values.agentEmail = `${process.env.GATSBY_TEST_EMAIL}`
      // } else {
      //   values.agentEmail = props.Broker.email
      // }
      // values.agentEmail = `${process.env.GATSBY_TEST_EMAIL}` //props.Broker.email
      values.agentEmail = props.Broker.email
      values.agentName = props.Broker.firstName
    }
    if (!request) {
      try {
        setRequest(true)
        setLoading(true)
        const res = await axios.post(
          `${process.env.GATSBY_BACKEND_API_URL}/appointments/bookings/call`,
          values,
          {}
        )
        setSubmitSuccess(true)
        resetForm(initialValues)
        if (window.pageYOffset !== 0) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
        setRequest(false)
        setLoading(false)
      } catch (error) {
        setSubmitSuccess(false)
        setLoading(false)
      }
    }
  }

  function handleDateUpdate(e) {
    let weekdays = [...week]
    const selectedIndex = weekdays.findIndex(i => i.checked == true)
    if (selectedIndex >= 0) {
      let selectedDate = weekdays[selectedIndex]
      const obj = {
        id: selectedDate.id,
        day: selectedDate.day,
        date: selectedDate.date,
        isodate: selectedDate.isodate,
        checked: false,
      }
      weekdays[selectedIndex] = obj
    }
    const index = weekdays.findIndex(i => i.id == e.target.id)
    if (index >= 0) {
      let weekday = weekdays[index]
      const obj = {
        id: weekday.id,
        day: weekday.day,
        date: weekday.date,
        isodate: weekday.isodate,
        checked: true,
      }
      weekdays[index] = obj
      generateSlots(weekday.isodate)
      setSelectedDate(weekday.isodate)
    }
    setWeek(weekdays)
  }

  return (
    <section className="contact-area">
      {SubmitSuccess ? (
        <Success subTitle="We look forward to speaking with you soon." />
      ) : null}
      {loading ? <Loader /> : null}
      <div className="contact-form">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form id="contactForm">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    placeholder="Your first name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="firstName">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    placeholder="Your last name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="lastName" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Mobile Phone</label>
                  <Field
                    type="tel"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Your mobile number"
                    validate={ValidatePhone}
                  />
                  <ErrorMessage name="phone" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>E-mail</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Your email address"
                    validate={ValidateEmail}
                  />
                  <ErrorMessage name="email" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Postcode</label>
                  <Field
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    className="form-control"
                    placeholder="Your postcode"
                    validate={ValidatePostCode}
                  />
                  <ErrorMessage name="postalCode" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              {/* booking date slot area  */}
              <div className="col-12 booking-area">
                <h3>What day and time slot works best for you?</h3>
                <div className="select-date">
                  <h5 className="select-date-label">Select Date</h5>
                  <ErrorMessage name="requestedDate" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <div className="select-date__wrapper">
                    {week.map((date, index) => (
                      <div
                        className="select-date__datebox"
                        key={date.id}
                        id={date.id}
                      >
                        <Field
                          type="radio"
                          name="requestedDate"
                          id="requestedDate"
                          id={date.id}
                          checked={date.checked}
                          onChange={handleDateUpdate}
                          // validate={ValidateDateSlot}
                        />
                        <div className="checkmark">
                          <h4>{date.day}</h4>
                          <h3>{date.date}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="select-slot">
                  <h5 className="select-slot-label">Select Slot</h5>
                  <ErrorMessage name="requestedTimeSlot" className="form-error">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  {timeslot.map((item, index) => {
                    return (
                      <div className="select-slot__slotbox" key={item}>
                        <Field
                          type="radio"
                          name="requestedTimeSlot"
                          id="requestedTimeSlot"
                          value={item}
                          validate={ValidateTimeSlot}
                        />
                        <div className="checkmark">
                          <h4>{item}</h4>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-12">
                <div className="remember-me-wrap">
                  <p>
                    <Field
                      type="checkbox"
                      id="subscribeToNewsletter"
                      name="subscribeToNewsletter"
                      className="form-control"
                    />
                    <label>
                      Subscribe to our newsletter to receive offers and updates.
                    </label>
                  </p>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <button type="submit" className="default-btn primary-btn">
                  Book a Slot <span></span>
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  )
}

export default BookingForm
